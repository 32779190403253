// 全局配置菜单文件
// 后台logo配置 navBar后台菜单配置
export default {
	logo: '集天下美文创管理系统',
	navBar: {
		active: '0',
		list: [{
				name: "首页",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-home",
					name: "后台首页",
					pathname: "index"
				}, {
					icon: "el-icon-picture",
					name: "相册管理",
					pathname: "image"
				}]
			},{
				name: "商品",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-claim",
					name: "商品列表",
					pathname: "shop_goods_list"
				},{
					icon: "el-icon-s-help",
					name: "签到列表",
					pathname: "qd_lb_list"
				},{
					icon: "el-icon-s-order",
					name: "签到记录",
					pathname: "qd_jl_list"
				}]
			},
			{
				name: "订单",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-order",
					name: "订单列表",
					pathname: "order_order_list"
				}]
			},
			{
				name: "会员",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-custom",
					name: "会员列表",
					pathname: "user_user-list_list"
				},{
					icon: "el-icon-medal-1",
					name: "会员等级",
					pathname: "user_user-level_list"
				},]
			},
			{
				name: "设置",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-tools",
					name: "基础设置",
					pathname: "set_base"
				},{
					icon: "el-icon-truck",
					name: "物流设置",
					pathname: "set_express"
				},{
					icon: "el-icon-s-platform",
					name: "管理员列表",
					pathname: "set_manager"
				},{
					icon: "el-icon-s-finance",
					name: "交易设置",
					pathname: "set_payment"
				},{
					icon: "el-icon-s-claim",
					name: "app首页设置",
					pathname: "app_index"
				}]
			},
			{
				name: "世界",
				subActive: '0',
				submenu: [{
					icon: "el-icon-s-tools",
					name: "世界列表",
					pathname: "word_sjlb_list"
				},{
					icon: "el-icon-truck",
					name: "世界状态",
					pathname: "word_sjzt_list"
				},{
					icon: "el-icon-s-platform",
					name: "怪物列表",
					pathname: "word_gwlb_list"
				},{
					icon: "el-icon-s-finance",
					name: "怪物分类",
					pathname: "word_gwfl_list"
				},{
					icon: "el-icon-s-claim",
					name: "怪物模型",
					pathname: "word_gwmx_list"
				},{
					icon: "el-icon-truck",
					name: "物品列表",
					pathname: "word_wplb_list"
				},{
					icon: "el-icon-s-platform",
					name: "物品分类",
					pathname: "word_wpfl_list"
				},{
					icon: "el-icon-s-finance",
					name: "市场列表",
					pathname: "word_sclb_list"
				},{
					icon: "el-icon-s-claim",
					name: "市场规则",
					pathname: "word_scgz_list"
				},{
					icon: "el-icon-s-claim",
					name: "属性列表",
					pathname: "word_sxlb_list"
				}]
			}
		]
	}

}
