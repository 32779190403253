// 全局功能
import Vue from 'vue'
import './plugins/axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router.js'
import store from './store'
import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
// import VueParticles from 'vue-particles' //登录背景动画
// Vue.use(VueParticles)
import {
	Message,
	Loading
} from 'element-ui'

// 全局等待提示 定义loading动画
let loading = null
let requestCount = 0
// 显示loading
function showLoading() {
	if(requestCount === 0){
		loading = Message({
		  message: '加载中...',
		  duration:0
		});
	}
	requestCount++
}
// 隐藏loading
function hideLoading() {
	if(requestCount > 0){
		requestCount--
	}
	if(loading && requestCount === 0){
		loading.close()
	}
}

// 多个请求
let needLoadingRequestCount = 0

function showFullScreenLoading() {
	if (needLoadingRequestCount === 0) {
		showLoading()
	}
	needLoadingRequestCount++
}

function tryHideFullScreenLoading() {
	if (needLoadingRequestCount <= 0) return
	needLoadingRequestCount--
	if (needLoadingRequestCount === 0) {
		hideLoading()
	}
}

// 添加请求拦截器 -全局登录提示
axios.interceptors.request.use((config) => {
	// 全局添加header头的token
	if (config.token === true) {
		config.headers['token'] = window.sessionStorage.getItem('token')
	}
	// 文件
	if (config.file === true) {
		config.headers['Content-Type'] = 'multipart/form-data'
	}
	// 显示loading
	if (config.loading === true) {
		showFullScreenLoading()
	}
	return config;
}, error => {
	return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
	// 隐藏loading
	tryHideFullScreenLoading()
	// 对响应数据做点什么
	return response;
}, (err) => {
	// 输入账号密码，提示错误输入 -全局错误提示
	if (err.response && err.response.data && err.response.data.errorCode) {
		Message.error(err.response.data.msg)
	}
	// 隐藏loading
	tryHideFullScreenLoading()
	// 对响应错误做点什么
	return Promise.reject(error);
});

// 引入拖拽排序
import VueDND from 'awe-dnd'
Vue.use(VueDND)

// 全局自定义指令 实现授权验证 
Vue.directive('auth', {
	inserted(el, binding, vnode) {
		// 超级管理员
		if (!store.state.user.user.super) {
			// 验证权限
			let rules = store.state.user.ruleNames
			let i = rules.findIndex(v => v === binding.value)
			if (i === -1) {
				el.parentNode.removeChild(el);
			}
		}
	},
})


// 引入全局配置文件
import $conf from "./common/config/config.js"
Vue.prototype.$conf = $conf

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
	router,
	store,
	el: '#app',
	render: h => h(App),
}).$mount('#app');
